import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Row, Container, Col, Card, Accordion } from 'react-bootstrap/'
import { FaSortDown, FaCircle } from 'react-icons/fa';
import TopHeader from "../components/top-header"

import { injectIntl, FormattedMessage, Link } from "gatsby-plugin-intl"
import Kosgeb from "../data/kosgeb.yaml"

const KosgebLP = ({ intl }) => {
  return (
    <Layout>
      <TopHeader />
      <SEO
        title={Kosgeb.title}
        description={Kosgeb.seoDesc}
        imageURL={Kosgeb.SocialCard} />
      <section>
        <Container>
  
        </Container>
        <Container className='kosgeb-lp'>
          <h1>{Kosgeb.title}</h1>
          <Row>
            <Col sm className='retmes-lp-ml-col-2'>
              <br />
              <div dangerouslySetInnerHTML={{ __html: Kosgeb.desc }} />
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container className='kosgeb-lp' style={{
          marginTop: '2rem'
        }}>
          <Accordion className='ai-accordion'>
            {Kosgeb.content.map((Kosgeb, index) => (
                <Card  key={`content_item_${index}`}  className='ai-card' id='to-accordion'>
                  <Accordion.Toggle as={Card.Header} className='justify-content-between' eventKey={index}>
                    <FaCircle style={{ fill: '#67a9c6' }} />
                    <h2 className='kosgeb-lp'
                     dangerouslySetInnerHTML={{ __html: Kosgeb.question }}
                    />
                    <FaSortDown />
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={index}>
                    <Card.Body>
                    <p className='kosgeb-lp'
                     dangerouslySetInnerHTML={{ __html: Kosgeb.answer }}
                    />
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
            ))}
          </Accordion>
          <Row className='home-row-2' >
            <Col sm style={{ alignSelf: 'center', marginTop: '1em', marginBottom: '0px' }} className='head-col-1'  >
              <strong className='support-title'><FormattedMessage id="modules_ad_2_title_h1" /></strong> <br />
              <p style={{ fontWeight: '200', paddingTop: '30px', textAlign: '-webkit-center' }}><FormattedMessage id="solutions_ad_desc_p" /></p> <br />
              <Link to='/iletisim'> <div className='retmes-detail-button'><FormattedMessage id="get_support" /> </div></Link>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export default injectIntl(KosgebLP)
